import React from "react";
/* eslint-disable max-len */

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Newsletter from "../components/template-parts/Newsletter";

function Newsletter2() {
  return (
    <Layout>
      <SEO
        title="Newsletter"
        keywords="helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação, fmusp"
        description="Inscreva-se para receber novidades!"
      />
      <Newsletter />
    </Layout>
  );
}

export default Newsletter2;
